<template>
  <el-row class="search-container" :gutter="20">
    <el-col class="inp" :span="18">
      <el-input
        v-model="searchText"
        placeholder="请输入订单号/快递单号"
      ></el-input>
    </el-col>
    <el-col :span="6">
      <el-button type="primary" @click="handleSearchClick">搜索</el-button>
    </el-col>
    <!-- <el-col :span="8">
      <el-button type="primary">搜索买家昵称</el-button>
    </el-col> -->
    <!-- <el-col :span="8">
      <el-button type="primary">搜索快递单号</el-button>
    </el-col> -->
  </el-row>
</template>
<script>
export default {
  name: "Search",
  data() {
    return {
      searchText: "",
    };
  },
  watch: {
    searchText(e) {
      localStorage.deliverSearchText = e;
    },
  },
  created() {
    try {
      if (localStorage.deliverSearchText) {
        this.searchText = localStorage.deliverSearchText;
        this.$emit("search", this.searchText);
      }
    } catch (error) {}
  },
  methods: {
    handleSearchClick() {
      if (this.searchText === "") {
        this.$message({
          message: "不能为空",
          warning: "warning",
        });
        return;
      }
      this.$emit("search", this.searchText);
    },
  },
};
</script>
<style lang="less" scoped>
.search-container {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  .inp {
    // margin-bottom: 10px;
  }
}
</style>