import { render, staticRenderFns } from "./CompeleteBatches.vue?vue&type=template&id=58186672&scoped=true&"
import script from "./CompeleteBatches.vue?vue&type=script&lang=js&"
export * from "./CompeleteBatches.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58186672",
  null
  
)

export default component.exports