<template>
  <el-row class="message-container">
    <div class="table">
      <div class="item">
        <div class="title">买家昵称：</div>
        <div class="message" v-if="list.length > 0">
          {{ item.nicker_name }}
        </div>
      </div>
      <div class="item">
        <div class="title">所属店铺：</div>
        <div class="message" v-if="list.length > 0">
          {{ item.shop_name }}
        </div>
      </div>
      <div class="item">
        <div class="title">快递公司：</div>
        <div class="message" v-if="list.length > 0">
          {{ item.express_name }}
        </div>
      </div>
      <div class="item">
        <div class="title">快递单号：</div>
        <div class="message" v-if="list.length > 0">
          {{ item.expressNo }}
        </div>
      </div>
      <div class="item">
        <div class="title">图片数量：</div>
        <div class="message" v-if="list.length > 0">
          {{ item.order_items | imgNum }}
        </div>
      </div>
      <div class="item">
        <div class="title">配件信息：</div>
        <div class="message" v-if="list.length > 0">
          {{ item.shop_name }}
        </div>
      </div>
      <div class="textarea-container">
        <div class="textarea">
          {{ item.seller_memo }}
        </div>
      </div>
    </div>
  </el-row>
</template>
<script>
export default {
  name: "Message",
  props: {
    list: Array,
    index: Number,
  },
  data() {
    return {
      item: {},
    };
  },
  watch: {
    index(i) {
      this.item = this.list[i];
    },
    list() {
      this.item = this.list[this.index];
    },
  },
  filters: {
    imgNum(items) {
      let num = 0;
      if (!items) {
        return;
      }
      items.forEach((item) => {
        num += item.images.length;
      });
      return num;
    },
  },
};
</script>
<style lang="less" scoped>
.message-container {
  width: 100%;
  //   padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .table {
    border: 1px solid #ccc;
    background-color: #fff;
  }
  .item {
    display: flex;
    height: 40px;
    border-bottom: 1px solid #ccc;
    .title {
      flex: 0 0 auto;
      padding: 0 10px;
      width: 90px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-right: 1px solid #ccc;
    }
    .message {
      padding: 0 10px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }
  }
  .textarea-container {
    // height: 200px;
    .textarea {
      padding: 6px;
      line-height: 1.5em;
      width: 100%;
      height: 200px;
      resize: none;
      border: none;
      box-sizing: border-box;
    }
  }
}
</style>