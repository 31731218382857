<template>
  <el-row class="handle-container">
    <el-row class="tab-container" :gutter="30">
      <el-col :span="2" v-for="(item, index) of tabList" :key="index">
        <el-button
          :plain="tabIndex !== index"
          type="primary"
          @click="handleTabClick(index)"
          >{{ item.name }}</el-button
        >
      </el-col>
    </el-row>
    <el-row class="list-container" :gutter="20">
      <template v-for="(item, index) of list">
        <el-col
          :span="isFull ? 3 : 6"
          :key="item.id"
          v-if="handleStatus(item.status)"
        >
          <div class="item">
            <div class="item-top">
              <el-row>
                <el-col :span="24"> 材质：{{ item.attrs[1].value_cn }} </el-col>
                <el-col :span="24"> 型号：{{ item.attrs[0].value_cn }} </el-col>
              </el-row>
              <el-row>
                <el-col :span="12"> 数量：{{ item.count }} </el-col>
                <el-col class="status" :span="12">
                  状态：{{ item.status_name }}
                </el-col>
              </el-row>
            </div>
            <el-row class="item-img">
              <el-carousel
                :autoplay="false"
                indicator-position="none"
                :loop="false"
                class="carousel-container"
              >
                <el-carousel-item
                  v-for="(child, index) in item.images"
                  :key="child.id"
                >
                  <ky-image
                    :images="item.images"
                    :src="base_url + child.cropimage"
                  ></ky-image>
                  <div class="position-container">
                    {{ index + 1 }}/{{ item.images.length }}
                  </div>
                </el-carousel-item>
              </el-carousel>
            </el-row>
            <el-row class="item-bottom">
              <el-button type="danger" @click="handleIssueClick(item)"
                >问题件</el-button
              >
              <el-button type="success" @click="handleComfirm(item.id, index)"
                >确认发货</el-button
              >
            </el-row>
          </div>
        </el-col>
      </template>
    </el-row>
    <el-row class="btn-bottom">
      <div class="btn-item" v-for="item of btnList" :key="item.id">
        <el-button :type="item.btnType" @click="handleBtnClick(item.type)">{{
          item.name
        }}</el-button>
      </div>
    </el-row>
    <issue
      :show="issueShow"
      :images="images"
      :id="orderItemId"
      :typeName="typeName"
      :stuffName="stuffName"
      :issueList="issueList"
    ></issue>
  </el-row>
</template>
<script>
import Issue from "./Issue";
import KyImage from "@/components/KyImage";
export default {
  components: { Issue, KyImage },
  name: "Handle",
  props: {
    list: Array,
    orderIndex: Number,
    isFull: Boolean,
  },
  data() {
    return {
      images: [],
      issueShow: true,
      issueList: [],
      tabIndex: 0,
      tabList: [
        {
          name: "待发货",
          status: 4,
        },
        {
          name: "已发货",
          status: 5,
        },
        {
          name: "问题件",
          status: 6,
        },
        {
          name: "所有",
          status: "all",
        },
        {
          name: "全屏",
          status: "full",
        },
      ],
      btnList: [
        // {
        //   btnType: "danger",
        //   name: "问题件",
        // },
        // {
        //   btnType: "info",
        //   name: "全部发货",
        // },
        // {
        //   btnType: "info",
        //   name: "图片打包",
        // },
        // {
        //   btnType: "info",
        //   name: "拦截所有",
        // },
        {
          btnType: "warning",
          type: "nextOrder",
          name: "下一个订单",
        },
      ],
      orderItemId: null,
      typeName: "",
      stuffName: "",
    };
  },
  watch: {
    orderlist(index) {
      this.item = this.orderlist[index];
    },
  },
  created() {
    this.getProblemTypes();
    try {
      if (localStorage.deliveryHandleTabIndex) {
        this.handleTabClick(localStorage.deliveryHandleTabIndex);
      }
    } catch (error) {}
  },
  methods: {
    //切换tab
    handleTabClick(index) {
      this.tabIndex = Number(index);
      this.$emit("updata");
      localStorage.deliveryHandleTabIndex = index;
      if (this.tabList[index].status === "full") {
        this.$emit("isFull", true);
        localStorage.deliveryHandleIsFull = this.isFull;
      } else {
        this.$emit("isFull", false);
      }
    },
    //设置为问题件
    handleIssueClick(item) {
      this.typeName = item.attrs[0].value_cn;
      this.stuffName = item.attrs[1].value_cn;
      this.orderItemId = item.id;
      this.images = item.images;
      this.issueShow = !this.issueShow;
    },
    //判断显示
    handleStatus(status) {
      switch (this.tabIndex) {
        case 0:
          return status < 5 ? true : false;
          break;
        case 1:
          return status === 5 ? true : false;
          break;
        case 2:
          return status === 6 ? true : false;
          break;
        case "all":
          return true;
          break;
        case "full":
          return true;
          break;
        default:
          return true;
          break;
      }
      // return true;
    },
    //点击底部按钮
    handleBtnClick(type) {
      switch (type) {
        case "nextOrder":
          this.$emit("nextOrder", this.orderIndex + 1);
          break;

        default:
          break;
      }
    },
    //获取问题列表
    async getProblemTypes() {
      const res = await this.$store.dispatch("getProblemTypes");
      this.issueList = res.items;
    },
    //确认发货
    async handleComfirm(id, index) {
      const res = await this.$store.dispatch("comfirmDeliver", {
        order_item_ids: JSON.stringify([id]),
      });
      if (res.code !== 0) {
        this.$tool.comfirmToast(res);
        return;
      }
      const itemArr = Object.values(res.item_specs);

      this.$tool.comfirmToast(itemArr[0]);
      this.$emit("splice", index);
    },
  },
};
</script>
<style lang="less" scoped>
.handle-container {
  padding: 0 30px;
  box-sizing: border-box;
  .tab-container {
    margin-bottom: 10px;
  }
  .list-container {
    height: 670px;
    display: flex;
    flex-wrap: wrap;
    background-color: #b7b1b1;
    overflow: auto;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 400px;
      background-color: #85dbd8;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      .item-top {
        padding: 5px 10px;
        width: 100%;
        height: 60px;
        background-color: #e8dbdb;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // align-items: center;
        // justify-content: center;
        .status {
          font-weight: bold;
          font-size: 14px;
          color: red;
          &.red {
            color: red;
          }
          &.succ {
            color: #67c23a;
          }
        }
      }
      .item-img {
        flex: 1 1 auto;
      }
      .item-bottom {
        width: 100%;
        height: 60px;
        background-color: #4fb3b3;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
  .btn-bottom {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-item {
      margin-right: 10px;
    }
  }
}
.carousel-container {
  width: 100%;
  height: 100%;
  .position-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
  }
  // .image-item {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   max-width: 100%;
  //   max-height: 100%;
  // }
}
</style>