
<template>
  <div class="wrapper">
    <!-- <el-row :gutter="20">
      <el-col :span="3">
        <el-button @click="refresh" icon="el-icon-refresh" type="warning"
          >刷新</el-button
        >
      </el-col>
    </el-row> -->
    <el-tabs v-model="activeTab" @tab-click="tabChange">
      <el-tab-pane v-if="bFahuo" :label="fahuoLabel" name="fahuo">
        <!-- <Delivery></Delivery> -->
        <delivery-handle></delivery-handle>
      </el-tab-pane>
      <el-tab-pane :label="waitingOpLabel" name="doing">
        <waitingForOperationBatch></waitingForOperationBatch>
      </el-tab-pane>
      <el-tab-pane :label="doneOpLabel" name="done">
        <CompeleteBatches></CompeleteBatches>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import waitingForOperationBatch from "./components/waitingForOperationBatch";
import CompeleteBatches from "./components/CompeleteBatches";
import Delivery from "./components/Delivery";
import DeliveryHandle from "./components/deliveryHandle/DeliveryHandle";
export default {
  components: {
    waitingForOperationBatch,
    CompeleteBatches,
    Delivery,
    DeliveryHandle,
  },
  inject: ["reload"],
  props: {},
  data() {
    return {
      curRole: "",
      activeTab: "fahuo",
      fahuoLabel: "发货",
      waitingOpLabel: "待打壳批次",
      doneOpLabel: "已完成批次",
      bFahuo: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    tabChange(tab) {},
    refresh() {
      this.reload();
    },
  },
  created() {},
  mounted() {
    this.curRole = this.$cookies.get("curRole");

    let text = "完成";
    this.bFahuo = false;
    if (this.curRole == "DK" || this.curRole == "DK_ADMIN") {
      text = "打壳";
    } else if (this.curRole == "DJ" || this.curRole == "DJ_ADMIN") {
      text = "点胶";
    } else if (this.curRole == "FH" || this.curRole == "FH_ADMIN") {
      text = "发货";
      this.bFahuo = true;
    }

    this.waitingOpLabel = "待" + text + "批次";
    this.doneOpLabel = "已" + text + "批次";
  },
};
</script>
<style lang="less" scoped>
.wrapper {
}
</style>
