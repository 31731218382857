<template>
  <div>
    <el-row :gutter="20">
      <el-col v-if="bShowCompelete" :span="3">
        <el-button type="primary" @click="doneBatches">{{
          compeleteText
        }}</el-button>
      </el-col>
    </el-row>
    <table-container :list="batches" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="批次名"> </el-table-column>
      <el-table-column prop="image_num" label="图片数量"> </el-table-column>
      <el-table-column prop="order_item_num" label="订单项数量">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showOrderItem(scope.row)"
            >查看订单项</el-button
          >
        </template>
      </el-table-column>
    </table-container>
    <el-dialog title="订单项列表" width="80%" :visible.sync="showingOrderItems">
      <table-container :list="orderItems">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column property="id" label="ID" width="50"></el-table-column>
        <el-table-column
          v-for="field in fields"
          :label="field.name"
          :key="field.name"
          :prop="field.name_en"
        >
          <template slot-scope="scope">
            <span>{{ getAttrValue(scope) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片" property="cropimage">
          <template slot-scope="scope">
            <div v-if="scope.row.sides.length > 0" style="z-index: 99999">
              <viewer
                :images="[baseUrl + scope.row.sides[0].cropimage]"
                style="z-index: 9999"
              >
                <img
                  :src="baseUrl + scope.row.sides[0].cropimage"
                  alt=""
                  style="width: auto; height: 60px"
                />
              </viewer>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="created_at"
          label="日期"
          width="150"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!--                        <el-button size="mini"-->
            <!--                                   type="text"-->
            <!--                                   @click="lookImage(scope.row)" v-if="scope.row.image_sides!=''">图片详情</el-button>-->
            <el-button size="mini" type="text" @click="addToProblem(scope.row)"
              >加问题件</el-button
            >
          </template>
        </el-table-column>
      </table-container>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        :total="total"
        :current-page.sync="page"
        @current-change="changePage"
      >
      </el-pagination>
    </el-dialog>

    <el-dialog title="加入问题件" width="18%" :visible.sync="addingToProblem">
      <el-form label-position="right" label-width="90px">
        <el-form-item label="问题类型:" prop="problemTypeId">
          <el-select
            v-model="addToProblemModel.problemTypeId"
            style="display: block"
          >
            <el-option
              v-for="problemType in problemTypes"
              :key="problemType.id"
              :value="problemType.id"
              :label="problemType.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题数量:" prop="num">
          <el-input
            v-model="addToProblemModel.num"
            placeholder="输入加入问题件数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="记录损耗:" prop="bAddWastate">
          <el-switch
            style="display: block"
            v-model="addToProblemModel.bAddWastate"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="记损耗"
            inactive-text="不记损耗"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd">取 消</el-button>
        <el-button type="primary" @click="sureAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import orderItemImage from "../../orderItemImage/index";
export default {
  name: "waitingForOperationBatch",
  components: {
    orderItemImage,
  },
  data() {
    return {
      baseUrl: "",
      batches: [],
      totalCount: 0,
      page: 0,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      selectedBatchIds: [],
      curRole: "",
      bShowCompelete: true,
      compeleteText: "完成",
      showingOrderItems: false,
      orderItems: [],
      fields: [],
      sides: [],
      total: 0,
      page: 0,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      showingItemImages: false,
      batchId: "",
      addingToProblem: false,
      problemTypes: [],
      addToProblemModel: {
        problemTypeId: "",
        num: 1,
        bAddWastate: true,
      },
    };
  },
  methods: {
    doneBatches() {
      this.completeBatches();
      this.updateData();
    },
    handleSelectionChange(val) {
      this.selectedBatchIds = [];
      for (let i = 0; i < val.length; i++) {
        this.selectedBatchIds.push(val[i].id);
      }
    },
    async completeBatches() {
      let param = {
        batchids: JSON.stringify(this.selectedBatchIds),
      };
      let data = await this.$api.flowApi.completeBatches(param);
    },
    async updateData() {
      let param = {
        batch_type: 2,
      };
      let data = await this.$api.flowApi.getAdminBatchs(param);
      this.batches = data.batches;
      this.totalCount = data.total;
      this.page = data.page;
      this.pageSize = data.pageSize;
    },
    init() {
      this.curRole = this.$cookies.get("curRole");
      this.compeleteText = "完成";
      this.bShowCompelete = true;
      if (this.curRole == "DK" || this.curRole == "DK_ADMIN") {
        this.compeleteText = "打壳完成";
      } else if (this.curRole == "DJ" || this.curRole == "DJ_ADMIN") {
        this.compeleteText = "点胶完成";
      } else if (this.curRole == "FH" || this.curRole == "FH_ADMIN") {
        this.compeleteText = "发货完成";
        this.bShowCompelete = false;
      }
    },
    showOrderItem(row) {
      this.batchId = row.id;
      this.getBatchOrderItems();
    },
    addToProblem(row) {
      this.addingToProblem = true;
    },
    // lookImage(row){
    //
    // },
    changePage() {
      this.page = page;
      this.getBatchOrderItems();
    },
    cancelAdd() {},
    sureAdd() {},
    getAttrValue(scope) {
      return scope.row.attrs[scope.column.property].value;
    },
    async getBatchOrderItems() {
      let data = await this.$api.flowApi.getBatchItems({
        batch_id: this.batchId,
        page: this.page,
        pageSize: this.pageSize,
      });
      if (data.code != 0) {
        this.$message({
          message: "获取订单项失败",
          type: "error",
        });
        return;
      }
      this.fields = data.fields;
      this.orderItems = data.items;
      this.showingOrderItems = true;
    },
    async getProblemTypes() {
      let data = await this.$api.flowApi.problemTypes();
      this.problemTypes = data.items;
    },
  },
  mounted() {
    this.init();
    this.updateData();
  },
  created() {
    this.baseUrl = this.base_url;
    this.getProblemTypes();
    this.init();
  },
};
</script>

<style scoped>
</style>
