<template>
  <div class="Delivery">
    <el-row :gutter="20">
      <el-col :span="3">
        <el-input v-model="ordersn" placeholder="请输入订单号"></el-input>
      </el-col>
      <el-col :span="3">
        <el-input v-model="expressno" placeholder="请输入快递单号"></el-input>
      </el-col>
      <el-col :span="3">
        <el-input v-model="nickername" placeholder="请输入买家昵称"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary">搜索</el-button>
      </el-col>
    </el-row>

    <el-collapse v-model="ordersns" accordion>
      <el-collapse-item name="summary">
        <template slot="title">
          <div style="display: flex; flex-direction: row">汇总信息</div>
        </template>
        <table-container :list="summaryDatas">
          <el-table-column prop="name_cn" label="统计"> </el-table-column>
          <el-table-column prop="value" label="结果"> </el-table-column>
        </table-container>
      </el-collapse-item>
      <el-collapse-item
        v-for="item in orders"
        :title="item.ordersn"
        :name="item.ordersn"
        :key="item.id"
        style="overflow-y: auto"
      >
        <template slot="title">
          <div style="display: flex; flex-direction: row">
            订单号: {{ item.ordersn }}
          </div>
        </template>
        <div class="OrderItems">
          <div
            v-for="orderItem in item.items"
            class="OrderItem"
            :key="orderItem.id"
          >
            {{ orderItem }}
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "Delivery",
  data() {
    return {
      ordersn: "",
      expressno: "",
      nickername: "",
      ordersns: [],
      orders: [
        {
          ordersn: "2323",
          items: [1, 2],
        },
        {
          ordersn: "2323r",
          items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 12, 13, 14, 15, 16],
        },
      ],
      summaryDatas: [
        {
          name: "buyer_nicker",
          name_cn: "买家昵称",
          value: "",
        },
        {
          name: "agent_name",
          name_cn: "代理名称",
          value: "",
        },
        {
          name: "express_name",
          name_cn: "快递公司名称",
          value: "",
        },
        {
          name: "express_no",
          name_cn: "快递单号",
          value: "",
        },
        {
          name: "image_num",
          name_cn: "图片数量",
          value: "",
        },
        {
          name: "part_info",
          name_cn: "配件信息",
          value: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.OrderItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 400px;
}
.OrderItem {
  margin-top: 20px;
  margin-left: 20px;
  width: 200px;
  min-width: 200px;
  min-height: 400px;
  height: auto;
  background-color: blue;
  overflow: auto;
}
</style>
