<template>
  <ky-dialog title="添加问题件" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
      ref="fromData"
    >
      <el-form-item label="材质:">
        <el-col :span="14">
          <el-input disabled v-model="stuffName"></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="型号:">
        <el-col :span="14">
          <el-input disabled v-model="typeName"></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="图片:" prop="img">
        <div class="image-container">
          <ky-image :images="images" :src="base_url + img"></ky-image>
        </div>
      </el-form-item>
      <el-form-item label="问题:" prop="stuff_id">
        <el-col :span="14">
          <el-select v-model="issueId" placeholder="请选择问题类型">
            <template v-if="issueList">
              <el-option
                v-for="item of issueList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </template>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="备注:" prop="remark">
        <el-col :span="14">
          <el-input
            type="textarea"
            resize="none"
            placeholder="请输入内容"
            v-model="remark"
          >
          </el-input
        ></el-col>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
import KyImage from "@/components/KyImage";
export default {
  name: "Issue",
  props: {
    show: Boolean,
    images: Array,
    imageId: [String, Number],
    typeName: String,
    stuffName: String,
    id: [String, Number],
    issueList: Array,
  },
  components: {
    KyImage,
  },
  data() {
    return {
      stuff: "粉色玻璃壳",
      type: "苹果6",
      img:
        "/storage/order_images/20200914/1207265559671447787_华为P30_白磨砂软壳_80_7ed5f3c5ca81bbb795b52725a402e02a.png",
      labelPosition: "right",
      issueId: "",
      remark: "",
      //   issueList: [],
      hide: false,
    };
  },
  created() {},
  methods: {
    handleCancel() {
      this.hide = !this.hide;
    },
    handleComfirm() {
      this.$store
        .dispatch("addImageIssue", {
          order_item_id: this.id,
          problem_type: this.issueId,
          remark: this.remark,
        })
        .then((res) => {
          return this.$tool.comfirmToast(res);
        })
        .then(() => {
          this.hide = !this.hide;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.image-container {
  width: 100px;
  height: 100px;
  position: relative;
}
</style>