
<template>
  <div class="wrapper1">
    <table-container :list="sides">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column property="id" label="ID" width="50"></el-table-column>
      <el-table-column property="side_name" label="定制面"></el-table-column>
      <el-table-column property="status_name" label="状态"></el-table-column>
      <el-table-column label="图片" property="cropimage">
        <template slot-scope="scope">
          <div style="z-index: 99999">
            <viewer
              :images="[baseUrl + scope.row.cropimage]"
              style="z-index: 9999"
            >
              <img
                :src="baseUrl + scope.row.cropimage"
                alt=""
                style="width: auto; height: 60px"
              />
            </viewer>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        property="created_at"
        label="日期"
        width="160"
      ></el-table-column>
      <!-- <el-table-column label="操作"
                       width="100">
        <template slot-scope="scope">
          <el-button size="mini"
                     type="text"
                     @click="lookImage(scope.row)">添加为问题件</el-button>
        </template>
      </el-table-column> -->
    </table-container>
    <el-dialog
      title="添加问题件"
      :visible.sync="dialogFormVisible"
      append-to-body
      width="30%"
    >
      <el-form :model="form">
        <el-form-item label="订单项ID" label-width="90px">
          <el-input
            v-model="form.order_item_id"
            autocomplete="off"
            :placeholder="order_item_id"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="问题类型" label-width="90px">
          <el-input
            v-model="form.problem_type"
            autocomplete="off"
            placeholder="1"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="90px">
          <el-input
            type="textarea"
            v-model="form.remark"
            maxlength="256"
            show-word-limit
            :autosize="{ minRows: 5 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="lookImage">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["sides", "dialogFormVisible1", "order_item_id"],
  data() {
    return {
      imageData: [],
      baseUrl: "",
      dialogFormVisible: false,
      form: {
        order_item_id: "",
        problem_type: 1,
        remark: "",
      },
    };
  },
  watch: {
    // sides(newVal, oldVal) {
    //   this.imageData = newVal
    // },
    dialogFormVisible1(newVal, oldVal) {
      this.dialogFormVisible = newVal;
    },
    dialogFormVisible(newVal, oldVal) {
      this.$emit("changeProblem", newVal);
    },
  },
  computed: {},
  methods: {
    //添加问题件
    async lookImage() {
      this.form.order_item_id = this.order_item_id;
      let data = await this.$api.flowApi.addToItemWithProblem(this.form);
      if (data.code != 0) {
        this.$message({
          message: data.msg,
          type: "error",
        });
        return;
      }
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.dialogFormVisible = false;
      }
    },
  },
  created() {},
  mounted() {
    this.baseUrl = this.base_url;
  },
};
</script>
<style lang="less" scoped>
.wrapper1 {
  width: 100%;
}
</style>
