<template>
  <div>
    <table-container :list="batches" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="批次名"> </el-table-column>
      <el-table-column prop="image_num" label="图片数量"> </el-table-column>
      <el-table-column prop="order_item_num" label="订单项数量">
      </el-table-column>
      <el-table-column prop="dk_admin" label="打壳人员"> </el-table-column>
      <el-table-column prop="dk_time" label="打壳完成时间"> </el-table-column>
      <el-table-column prop="dk_msg" label="打壳备注"> </el-table-column>
      <el-table-column prop="dj_admin" label="点胶人员"> </el-table-column>
      <el-table-column prop="dj_time" label="点胶完成时间"> </el-table-column>
      <el-table-column prop="dj_msg" label="点胶备注"> </el-table-column>
      <el-table-column prop="fh_admin" label="发货人员"> </el-table-column>
      <el-table-column prop="fh_time" label="发货完成时间"> </el-table-column>
      <el-table-column prop="fh_msg" label="发货备注"> </el-table-column>
    </table-container>
  </div>
</template>

<script>
export default {
  name: "CompeleteBatches",
  data() {
    return {
      batches: [],
      totalCount: 0,
      page: 0,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
    };
  },
  methods: {
    handleSelectionChange(val) {},
    async updateData() {
      let param = {
        batch_type: 1,
      };
      let data = await this.$api.flowApi.getAdminBatchs(param);
      this.batches = data.batches;
      this.totalCount = data.total;
      this.page = data.page;
      this.pageSize = data.pageSize;
    },
  },
  mounted() {
    this.updateData();
  },
  created() {},
};
</script>

<style scoped>
</style>
