<template>
  <el-row class="list-container" v-if="list.length > 0">
    <!-- <el-row class="tab-container" :gutter="30">
      <el-col :span="5" v-for="(item, index) of tabList" :key="index">
        <el-button
          :plain="tanIndex !== index"
          type="primary"
          @click="handleTabClick(index)"
          >{{ item.name }}</el-button
        >
      </el-col>
    </el-row> -->
    <el-row class="list">
      <div
        class="item"
        :class="index === orderIndex ? 'active' : ''"
        v-for="(item, index) of list"
        :key="item.id"
        @click="handleItemClick(index)"
      >
        <div class="message-item">订单号：{{ item.ordersn }}</div>
        <div class="message-item">买家昵称：{{ item.nicker_name }}</div>
        <div class="message-item">快递公司：{{ item.express_name }}</div>
        <!-- <div class="message-item">配件信息：{{ item.parts }}</div> -->
      </div>
    </el-row>
  </el-row>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  data() {
    return {
      tanIndex: 0,
      tabList: [
        {
          name: "未完成",
        },
        {
          name: "所有",
        },
      ],
      orderIndex: 0,
      orderList: [
        {
          name: "moming",
          ordersn: "12321321321",
          express: "中通快递",
          parts: "配件信息",
        },
        {
          name: "moming",
          ordersn: "12321321321",
          express: "中通快递",
          parts: "配件信息",
        },
        {
          name: "moming",
          ordersn: "12321321321",
          express: "中通快递",
          parts: "配件信息",
        },
        {
          name: "moming",
          ordersn: "12321321321",
          express: "中通快递",
          parts: "配件信息",
        },
      ],
    };
  },
  watch: {
    orderIndex(e) {
      this.$emit("index", e);
    },
  },
  methods: {
    handleItemClick(index) {
      this.orderList = index;
    },
  },
};
</script>
<style lang="less" scoped>
.list-container {
  .list {
    max-height: 670px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #b7b1b1;
    box-sizing: border-box;
    .item {
      cursor: pointer;
      padding: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #b7b1b1;
      &.active,
      &:hover {
        background-color: #4fb3b3;
        color: #fff;
        transition: background-color 0.25s ease;
      }
      &:last-child {
        border-bottom: none;
      }
      .message-item {
        line-height: 2em;
      }
    }
  }
}
</style>